<template>
  <div>
    <h1>Comissões</h1>
    <div v-if="loading" class="d-flex justify-content-center m-2">
        <b-spinner variant="primary" />
    </div>
    <template v-else-if="items.length > 0">
      <b-card
        no-body
        class="mb-0 m-2"
      >
        <b-table
          :items="items"
          striped
          borderless
          responsive
          :fields="tableColumns"
          show-empty
          empty-text="Não foram encontradas comissões"
          :busy="loading"
        >
          <!-- Column: installment -->
          <template #cell(installment)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{ data.item.installments }}</span>
            </div>
          </template>
          <!-- Column: consultant -->
          <template #cell(consultant)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{ data.item.consultant.name }}</span>
            </div>
          </template>
          <!-- Column: role -->
          <template #cell(role_name)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">
                <template v-if="data.item.role_name">
                  {{ data.item.role_name }}
                </template>
                <template v-else>
                  Removido
                </template>
              </span>
            </div>
          </template>
          <!-- Column: reconciliation_date -->
          <template #cell(reconciliation_date)="data">
            <div class="text-wrap">
              <span v-if="data.item.reconciliation_date" class="align-text-top text-capitalize">{{ data.item.reconciliation_date | dateTime }}</span>
            </div>
          </template>
          <!-- Column: amount -->
          <template #cell(amount)="data">
            <div class="text-wrap">
              <span v-if="data.item.reconciliation_date" class="align-text-top text-capitalize">{{ data.item.amount | toCurrency }}</span>
            </div>
          </template>
          <template #cell(has_invoice)="data">
            <div class="text-wrap">
              <span
                v-if="data.item.invoice"
                class="align-text-top text-capitalize"
              >Sim</span>
              <span
                v-else
                class="align-text-top text-capitalize"
              >Não</span>
            </div>
          </template>
        </b-table>
      </b-card>
    </template>
    <template v-else>
      <span class="d-flex justify-content-center" >
        Não foram encontradas comissões
      </span>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardBody, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as sharedTypes from '@/modules/shared/store/types'
import * as types from '../store/types'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  setup() {
    return { toast: useToast() }
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: 'installment', label: 'Parcela' },
        { key: 'consultant', label: 'PN' },
        { key: 'role_name', label: 'Nível de comissionamento' },
        { key: 'reconciliation_date', label: 'Data de reconciliação' },
        { key: 'amount', label: 'Valor reconciliado' },
        { key: "has_invoice", label: "Comissão faturada" },
      ],
    }
  },
  computed: {
    ...mapGetters({
      commissions: types.COMMISSIONS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return _.orderBy(
        this.commissions,['id_consultant_role','consultant.name', 'installments'],
          ['desc', 'asc', 'asc']
      ) || []
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    ...mapActions({
      getCommissions: types.GET_COMMISSIONS
    }),
    get() {
      this.loading = true
      this.getCommissions({
        id: this.$route.params.id
      })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao buscar as comissões. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
